import defaultConfig from '@/configs/defaultConfig'
import axiosIns from '@/libs/axios'
import moment from 'moment'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  namespaced: true,
  state: {
    allHearings: [],
    hearingList: [],
    datesList: [],
    courtsList: [],
    selectedDatesList: [],
    selectedCourtsList: [],
    hearingLoading: false,
    exportHearingLoading: false,
    exportEntireLoading: false,
    hearingDownloadLoading: false,
    currentEntireDate: '',
    currentExportDate: '',
    courtsDisplayBoard: {},
    courtIds: [],
    myTimer: null
  },
  getters: {
    // getAllCauselists: (state) => {
    //   return state.allHearings
    // },
  },
  mutations: {
    setTimer(state, timer) {
      state.myTimer = timer
    },
    clearTimer(state) {
      if (state.myTimer) {
        clearInterval(state.myTimer)
        state.myTimer = null
      }
    },
    updateCourtDisplayBoard(state, { id, data }) {
      state.courtsDisplayBoard = { ...state.courtsDisplayBoard, [id]: data }
      console.log('Dosplay Boards: ', state.courtsDisplayBoard)
    },
    setCourtIds(state, payload) {
      if (payload?.data) {
        var hearings = payload.data.slice(0)
        // Function to sort hearings by date
        hearings.sort((a, b) => {
          let aDate = a.date.split('-')
          let bDate = b.date.split('-')
          let aD = `${aDate[2]}-${aDate[1]}-${aDate[0]}`
          let bD = `${bDate[2]}-${bDate[1]}-${bDate[0]}`
          return aD.localeCompare(bD)
        })

        // Getting today's date in the format 'dd-MM-yyyy'
        let now = moment().format('DD-MM-YYYY')

        // Filtering hearings for today
        if (hearings.length > 0 && hearings[0].date === now) {
          let cases = hearings[0].data.filter(
            (element) =>
            (element.hc_name.includes('hc-') || element.hc_name.includes('sci')) && !element.hc_name.includes('mediation')
          )
          
          let ids = [
            ...new Set(cases.map((element) => element.court_id.toString()))
          ]
          state.courtIds = ids
          console.log('Ids: ', state.courtIds)

          // You can now use `cases` and `ids` as needed in your component
        }
      }
    },
    setHearingData(state, payload) {
      if (payload?.data) {
        var hearings = payload.data.slice(0)
        console.log('Data', hearings)
        state.allHearings = hearings
        state.hearingList = hearings

        // Getting Dates List
        let dates = []
        // Iterate over allHearing and extract dates
        hearings.forEach((hDate) => {
          dates.push(hDate.date)
        })
        state.datesList = dates
        console.log('Hearing Dates -->', state.datesList)

        // Getting Court Ids

        let courtIds = []
        // Iterate over allHearing and extract court IDs
        hearings.forEach((hDate) => {
          hDate.data.forEach((dataItem) => {
            // Check if the court ID is not already in courtIds array
            if (
              !courtIds.some((court) => court.court_id === dataItem.court_id)
            ) {
              courtIds.push({
                court_id: dataItem.court_id,
                ctitle: dataItem.ctitle
              })
            }
          })
        })
        state.courtsList = courtIds
        console.log('Hearing Court Ids -->', courtIds)
      }
    },
    changeHearingLoading(state, val) {
      state.hearingLoading = val
    },
    ///
    changeExportHearingLoading(state, val) {
      state.exportHearingLoading = val
    },
    changeExportHearingDate(state, val) {
      state.currentExportDate = moment(val, 'YYYY-MM-DD').format('DD-MM-YYYY')
    },
    ///
    changeEntireLoading(state, val) {
      state.exportEntireLoading = val
    },
    changeHearingDownloadLoading(state, val) {
      state.hearingDownloadLoading = val
    },
    changeExportEntireDate(state, val) {
      state.currentEntireDate = moment(val, 'YYYY-MM-DD').format('DD-MM-YYYY')
    },

    clearHearingData(state) {
      state.allHearings = []
      state.hearingList = []
    },

    clearFilter(state) {
      state.selectedDatesList = []
      state.selectedCourtsList = []
      state.allHearings = state.hearingList
    },

    selectDate(state, date) {
      if (state.selectedDatesList.includes(date)) {
        // Remove the date from the selectedDatesList array
        const index = state.selectedDatesList.indexOf(date)
        state.selectedCourtsList.splice(index, 1)
      } else {
        // Add the date to the selectedDatesList array
        state.selectedDatesList.push(date)
      }
    },

    selectCourt(state, court) {
      // Check if the court is already in selectedCourtsList
      const index = state.selectedCourtsList.findIndex(
        (selectedCourt) => selectedCourt.court_id === court.court_id
      )

      if (index !== -1) {
        // If the court is found, remove it from selectedCourtsList
        state.selectedCourtsList.splice(index, 1)
      } else {
        // If the court is not found, add it to selectedCourtsList
        state.selectedCourtsList.push(court)
      }
    },

    selectDateList(state, val) {
      state.selectedDatesList = val
    },

    selectCourtList(state, val) {
      state.selectedCourtsList = val
    },

    filterHearing(state) {
      let hList = state.hearingList.slice() // Create a copy of hearingList

      if (state.selectedDatesList && state.selectedDatesList.length > 0) {
        hList = hList.filter((element) =>
          state.selectedDatesList.includes(element.date)
        )
      }

      let newData = []

      if (state.selectedCourtsList && state.selectedCourtsList.length > 0) {
        for (let hDate of hList) {
          // Create a copy of hDate to avoid mutating the original
          let hrn = { ...hDate }

          // Filter the data associated with the current hDate
          let filteredData = hrn.data.filter((entry) => {
            // Check if the court_id of the entry exists in selectedCourtList
            return state.selectedCourtsList.some(
              (court) => court.court_id === entry.court_id
            )
          })

          if (filteredData.length > 0) {
            hrn.data = filteredData
            // Add the updated hrn to newData
            newData.push(hrn)
          }
        }
      } else {
        newData = hList
      }
      state.allHearings = newData
      console.log('Hearing Length: ', state.allHearings)
    }
  },
  actions: {
    selectDateCourtFilter({ commit, dispatch }, { ctx, dates, courts }) {
      commit('selectDateList', dates)
      commit('selectCourtList', courts)
      commit('filterHearing')
      ctx.$bvModal.hide('modal-hearing-filter')
    },

    clearHearingFilter({ commit, dispatch }, { ctx, dates, courts }) {
      commit('selectDateList', dates)
      commit('selectCourtList', courts)
      commit('filterHearing')
      ctx.$bvModal.hide('modal-hearing-filter')
    },

    downloadHearing({ state, commit }, { ctx }) {
      commit('changeHearingDownloadLoading', true)
      const courtIds = state.selectedCourtsList.map(court => court.court_id);
      let payload = {
        dates: state.selectedDatesList,
        courts: courtIds
      }
      console.log(payload)
      return new Promise((resolve, reject) => {
        axiosIns
          .post(defaultConfig.hearingDownloadEndPoint, payload)
          .then((response) => {
            commit('changeHearingDownloadLoading', false)
            console.log({ FetchingMatterData: JSON.stringify(response.data) })
            if (response.data.success) {
              return resolve(response.data)
            }else{
              const message = response.data.message || 'Failed to download!';
              ctx.$toast({
                component: ToastificationContent,
                props: {
                  title: '' + message,
                  icon: 'AlertTriangleIcon',
                  variant: 'danger'
                }
              })
            }
            return null
          })
          .catch((error) => {
            commit('changeHearingDownloadLoading', false)
            reject(error)
          })
      })
    },
    fetchHearings({ commit, dispatch }, ctx) {
      commit('changeHearingLoading', true)
      return new Promise((resolve, reject) => {
        axiosIns
          .get(defaultConfig.getHearingEndPoint)
          .then((response) => {
            commit('changeHearingLoading', false)
            console.log({ FetchingMatterData: JSON.stringify(response.data) })
            if (response.data.success) {
              commit('setHearingData', response.data)
              commit('clearFilter')
              commit('setCourtIds', response.data)
              dispatch('initHearingDisplayBoard')
            }
          })
          .catch((error) => {
            commit('changeHearingLoading', false)
            reject(error)
          })
      })
    },
    exportHearingByDate({ commit }, { ctx, date }) {
      commit('changeExportHearingLoading', true)
      commit('changeExportHearingDate', date)
      return new Promise((resolve, reject) => {
        axiosIns
          .get(defaultConfig.hearingExportByDateEndPoint({ date }))
          .then((response) => {
            commit('changeExportHearingLoading', false)
            commit('changeExportHearingDate', '')
            console.log({ FetchingExport: response.data })
            if (response.data.success) {
              console.log({ SuccessExport: response.data })
              return resolve(response.data)
            } else {
              ctx.$toast({
                component: ToastificationContent,
                props: {
                  title: '' + response.data.message,
                  icon: 'AlertTriangleIcon',
                  variant: 'danger'
                }
              })
              return null
            }
          })
          .catch((error) => {
            commit('changeExportHearingLoading', false)
            commit('changeExportHearingDate', '')
            reject(error)
            return null
          })
      })
    },
    exportEntireByDate({ commit }, { ctx, date }) {
      commit('changeEntireLoading', true)
      commit('changeExportEntireDate', date)
      return new Promise((resolve, reject) => {
        axiosIns
          .get(defaultConfig.entireExportByDateEndPoint({ date }))
          .then((response) => {
            commit('changeEntireLoading', false)
            commit('changeExportEntireDate', '')
            console.log({ FetchingEntire: response.data })
            if (response.data.success === true) {
              return resolve(response.data)
            } else {
              ctx.$toast({
                component: ToastificationContent,
                props: {
                  title: '' + response.data.message,
                  icon: 'AlertTriangleIcon',
                  variant: 'danger'
                }
              })
              return null
            }
          })
          .catch((error) => {
            commit('changeEntireLoading', false)
            commit('changeExportEntireDate', '')
            reject(error)
            return null
          })
      })
    },
    initHearingDisplayBoard({ state, commit, dispatch }) {
      const now = moment().format('HH')
      const formatDay = moment().format('dddd')
      if (formatDay !== 'Sunday') {
        if (parseInt(now) > 9 && parseInt(now) < 17) {
          commit('clearTimer')
          dispatch('fetchAllDisplayBoard')
          const timer = setInterval(() => {
            dispatch('fetchAllDisplayBoard')
            const currentNow = moment().format('HH')
            if (parseInt(currentNow) < 9 || parseInt(currentNow) > 17) {
              commit('clearTimer')
            }
          }, 60000)
          commit('setTimer', timer)
        } else {
          commit('clearTimer')
        }
      }
    },
    async fetchAllDisplayBoard({ state, commit }) {
      try {
        const requests = state.courtIds.map((id) => {
          const url = defaultConfig.getDisplayBoardEndPoint({ id })
          return axiosIns.get(url, {
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${localStorage.getItem('token')}`
            },
            id // attaching the courtId to the request
          })
        })

        const responses = await Promise.all(requests)

        responses.forEach((response) => {
          const { id } = response.config;
          if (response.data.success) {
            commit('updateCourtDisplayBoard', {
              id,
              data: response.data.data
            })
          } else {
            // Handle unsuccessful response
            this.$toast({
              component: ToastificationContent,
              props: {
                title: response.data.message,
                icon: 'AlertTriangleIcon',
                variant: 'danger'
              }
            })
          }
        })
      } catch (error) {
        console.error('Fetch error:', error)
        // Handle the error in the UI if needed
      } finally {
      }
    }
  }
}
